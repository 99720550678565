














































import Vue, { PropType } from 'vue'

import { formatMixIn } from '@/utils/mixin'
/**
 * フォーム部品。日付の From-To を入力
 */
export default Vue.extend({
  mixins: [formatMixIn],
  data() {
    return {
      value: ['', ''],
    }
  },
  created() {
    this.value = this.defaultValue
  },
  props: {
    /**
     * フォームのラベル。規定値は「入力日」
     */
    label: {
      type: String as PropType<string>,
      default: 'label.inputDate',
    },
    /**
     * From-Toの日付。v-model することを想定。
     */
    defaultValue: {
      type: Array as PropType<string[]>,
      default: () => ['', ''],
    },
    /**
     * 開始曜日
     */
    startDayOfWeek: {
      type: Number as PropType<number>,
      default: 0,
    },
    disabledTo: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  methods: {
    input1(v: string) {
      console.log(v)
      // console.log(this)
      this.value[0] = v
      /**
       * 日付が入力された場合に発火。From-Toの日付情報を返す。
       * 値は string で YYYY-MM-DD
       */
      this.$emit('input', [v, this.value[1]])
    },
    input2(v: string) {
      console.log(v)
      this.value[1] = v
      this.$emit('input', [this.value[0], v])
    },
  },
})
