






import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {},
  props: {},
  setup(_, context) {
    return {}
  },
})
