

















import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import DateTermInput from '@/components/molecules/c/DateTermInput.vue'
import ExtractConditionDateTermLayout from '@/components/organisms/h/layout/ExtractConditionDateTermLayout.vue'
import ExtractConditionRowLayout from '@/components/organisms/h/layout/ExtractConditionRowLayout.vue'
import {
  dateRange,
  dateGroupExtractCondition,
  itemHierarchyGroupExtractCondition,
} from '@/types/entities'

export default defineComponent({
  components: {
    DateTermInput,
    ExtractConditionDateTermLayout,
    ExtractConditionRowLayout,
  },
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    defaultDatevalue: {
      type: Array as PropType<string[]>,
      default: () => ['', ''],
    },
    disabledTo: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, context) {
    const dateRange = ref<dateRange>(['', ''])
    // いずれかの抽出条件変更時の処理
    const changeExtractCondition = () => {
      const cond: dateGroupExtractCondition = {
        dateRange: dateRange.value,
      }
      context.emit('change-extract-condition', cond)
    }
    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/

    watch(dateRange, (newVal) => {
      changeExtractCondition()
    })
    const defaultValue = props.defaultDatevalue
    return { dateRange, defaultValue }
  },
})
