



















import { defineComponent, getCurrentInstance, Ref } from '@vue/composition-api'
import { ApolloError, NetworkStatus } from 'apollo-client'

import PageView from '@/components/templates/h/common/autoorder-stop/PageView.vue'
import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import {
  autoorderStopExtractCondition,
  autoorderStopOutputCondition,
  ColumnLabel,
} from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
  VUiPAutoorderStopsCountDocument,
} from '@/types/generated/graphql'
import {
  OUTPUT_COND_DELETE,
  OUTPUT_COND_ITEM,
  OUTPUT_COND_STORE,
  OUTPUT_EXTRACT_TARGET_DEFAULT,
  EXPORT_FILENAME,
  SELECT_MAX_COUNT,
} from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { addDayYMD } from '@/utils/mixin'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast from '@/utils/toast'
const DEFAULT_OUTPUT_CONST = {
  extractTarget: OUTPUT_EXTRACT_TARGET_DEFAULT, // 出力条件（抽出対象）はデフォルト値を設定できるようにしていないため、定数を用意した
  storeOutput: [
    OUTPUT_COND_STORE.all,
    OUTPUT_COND_STORE.area,
    OUTPUT_COND_STORE.tanten,
  ],
  itemOutput: [
    OUTPUT_COND_ITEM.all,
    OUTPUT_COND_ITEM.shobunrui,
    OUTPUT_COND_ITEM.tanpin,
  ],
  delOutput: [],
  totalCount: null,
}

const DEFAULT_EXTRACT_CONST = {
  validDate: [addDayYMD(1), '2999-12-31'],
}
export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      defaultDateValue: DEFAULT_EXTRACT_CONST.validDate,
      resourceType: ResourceType.UiPAutoorderStop,
      templateFile: TemplateFile.UiPAutoorderStop_01,
      extractCondition: {
        validData: DEFAULT_EXTRACT_CONST.validDate,
        store: [],
        storeShelve: [],
        shobunrui: [],
        tanpin: [],
      },
      outputCondition: DEFAULT_OUTPUT_CONST,
      outputUseList: [
        {
          outputCondType: 'Store',
          displayItems: [
            OUTPUT_COND_STORE.all,
            OUTPUT_COND_STORE.area,
            OUTPUT_COND_STORE.tanten,
          ],
          defaultSelected: DEFAULT_OUTPUT_CONST.storeOutput,
          disabledItems: [],
        },
        {
          outputCondType: 'Item',
          displayItems: [
            OUTPUT_COND_ITEM.all,
            OUTPUT_COND_ITEM.shobunrui,
            OUTPUT_COND_ITEM.tanpin,
          ],
          defaultSelected: DEFAULT_OUTPUT_CONST.itemOutput,
          disabledItems: [],
        },
        {
          outputCondType: 'Delete',
          displayItems: [OUTPUT_COND_DELETE.includeDelete],
          defaultSelected: DEFAULT_OUTPUT_CONST.delOutput,
          disabledItems: [],
        },
      ],
      totalCount: null,
      exportSpinner: false,
    }
  },
  methods: {
    async changeExtractCondition(cond: autoorderStopExtractCondition) {
      console.log('changeExtractCondition', cond)
      this.extractCondition = cond
      this.requestCount()
    },
    changeOutputCondition(cond: autoorderStopOutputCondition) {
      this.outputCondition = cond
      this.requestCount()
    },
    async requestCount() {
      this.exportSpinner = true
      this.totalCount = null
      if (this.checkRequired(false)) {
        try {
          const result = await this.$apollo.query({
            query: VUiPAutoorderStopsCountDocument,
            variables: { where: this._createWhere() },
          })
          const data = result.data.vUiPAutoorderStops.totalCount
          this.totalCount = data.toString()
          this.exportSpinner = false
        } catch (e) {
          console.error(e)
          this.exportSpinner = false
          this.totalCount = '-'
        }
      } else {
        this.exportSpinner = false
        this.totalCount = '-'
      }
    },
    //　入力チェック
    checkRequired(showToast: boolean) {
      // 入力チェック開始
      let errMsg = []
      // 必須項目チェック
      if (
        !this.extractCondition.validData[0] ||
        !this.extractCondition.validData[1] ||
        (this.extractCondition.storeShelve.length == 0 &&
          this.extractCondition.store.length == 0 &&
          this.extractCondition.shobunrui.length == 0 &&
          this.extractCondition.tanpin.length == 0)
      ) {
        errMsg.push({
          message: this.$i18n.t('error.requiredItemNotInput'),
        })
      }
      // 選択数上限チェック
      if (this.extractCondition.storeShelveCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.areaStore'),
            selected: this.extractCondition.storeShelveCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.storeCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.store'),
            selected: this.extractCondition.storeCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.shobunruiCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.shobunrui'),
            selected: this.extractCondition.shobunruiCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.tanpinCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.tanpin'),
            selected: this.extractCondition.tanpinCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (errMsg.length) {
        if (showToast) {
          for (const msg of errMsg) {
            Toast.error(this, msg)
          }
        }
        // 入力チェックに引っかかった場合はエクスポートの要求を出さない
        return false
      }
      return true
    },
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /*********************************
     * 以下はエクスポートリクエスト用の処理
     *********************************/
    /**
     * エクスポート処理
     */
    async requestExport() {
      if (this.checkRequired(true)) {
        // this._create_query_string()
        await this.helper.export({
          query: this._create_query_string(),
          jmespathQuery:
            'data.vUiPAutoorderStops.edges[].node | map(&merge(`{"__update": null}`, @), @)',
          templateFile: this.templateFile,
          filenameExport: EXPORT_FILENAME.autoorder_stop,
          columnLabelMapping: this._create_column_label_mapping(),
        })
      }
    },

    _create_query_string() {
      const where = this._createWhere()
      const rowQuery = `
query {
  vUiPAutoorderStops(
    where: ${this.helper.generateQueryWhereString(where)}
    orderBy: ${this._createOrderByString()}
  ) {
    edges {
      node {
        inactiveFlg
        storeApplyLevel
        vUiMStoreApplyLevel {
          storeApplyScopeName
        }
        storeApplyScope
        itemApplyLevel
        vUiMItemApplyLevel {
          itemApplyScopeName
        }
        itemApplyScope
        startDate
        endDate
        configuredFlg
        lastModifiedDatetime
        lastModifiedUserName
      }
    }
  }
}
`
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    _createWhere() {
      const TRAN_REF_ITEM_SEARCH_CONDITION: Where[] = [
        // 抽出条件
        { field: ['startDate'], op: WhereOp.Le, value: [''] },
        { field: ['endDateNvl'], op: WhereOp.Ge, value: [''] },

        // 店舗コード、棚パターン、小分類、商品コードの抽出条件
        {
          field: ['vUiMStoreApplyLevel/validStores/storeCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['vUiMStoreApplyLevel/vUiPAreas/areaCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['vUiMItemApplyLevel/uiMCategory1s/category1Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['vUiMItemApplyLevel/m2oItem/saleItemCd'],
          op: WhereOp.In,
          value: [''],
        },

        // 出力条件
        { field: ['storeApplyLevel'], op: WhereOp.In, value: [''] },
        { field: ['itemApplyLevel'], op: WhereOp.In, value: [''] },
        { field: ['inactiveFlg'], op: WhereOp.In, value: [''] },
        // 設定行フラグ　1固定
        { field: ['configuredFlg'], op: WhereOp.In, value: ['1'] },
      ]
      const where = JSON.parse(JSON.stringify(TRAN_REF_ITEM_SEARCH_CONDITION))
      setWhereValue(where, 'startDate', this.extractCondition.validData[1])
      setWhereValue(where, 'endDateNvl', this.extractCondition.validData[0])
      setWhereValue(
        where,
        'vUiMStoreApplyLevel/validStores/storeCd',
        this.extractCondition.store
      )
      setWhereValue(
        where,
        'vUiMStoreApplyLevel/vUiPAreas/areaCd',
        this.extractCondition.storeShelve
      )
      setWhereValue(
        where,
        'vUiMItemApplyLevel/uiMCategory1s/category1Cd',
        this.extractCondition.shobunrui
      )
      setWhereValue(
        where,
        'vUiMItemApplyLevel/m2oItem/saleItemCd',
        this.extractCondition.tanpin
      )

      setWhereValue(where, 'storeApplyLevel', this.outputCondition.storeOutput)
      setWhereValue(where, 'itemApplyLevel', this.outputCondition.itemOutput)
      setWhereValue(where, 'inactiveFlg', [
        '0', // 削除されていないデータは必ず出力する
        ...this.outputCondition.delOutput,
      ])
      console.log({ where })
      return where
    },
    _createOrderByString() {
      const orderBy: OrderBy[] = [
        {
          field: 'storeApplyLevel',
          direction: OrderByDirection.Desc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'storeApplyScope',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'itemApplyLevel',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'itemApplyScope',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'startDate',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'endDate',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
      console.log({ orderBy })
      return this.helper.generateQueryOrderByString(orderBy)
    },
    _create_column_label_mapping() {
      const columnLabelMapping: ColumnLabel[] = [
        {
          from: '__update',
          to: '',
        },
        {
          from: 'inactiveFlg',
          to: '',
        },
        {
          from: 'storeApplyLevel',
          to: '',
        },
        {
          from: 'storeApplyLevelName',
          to: '',
        },
        {
          from: 'storeApplyScope',
          to: '',
        },
        {
          from: 'vUiMStoreApplyLevel.storeApplyScopeName',
          to: '',
        },
        {
          from: 'itemApplyLevel',
          to: '',
        },
        {
          from: 'itemApplyLevelName',
          to: '',
        },
        {
          from: 'itemApplyScope',
          to: '',
        },
        {
          from: 'vUiMItemApplyLevel.itemApplyScopeName',
          to: '',
        },
        {
          from: 'startDate',
          to: '',
        },
        {
          from: 'endDate',
          to: '',
        },
        {
          from: 'configuredFlg',
          to: '',
        },
        {
          from: 'lastModifiedDatetime',
          to: '',
        },
        {
          from: 'lastModifiedUserName',
          to: '',
        },
      ]
      console.log({ columnLabelMapping })
      return columnLabelMapping
    },
  },
})
