var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{attrs:{"label-cols-lg":"2","label-size":"sm","label":_vm.$t(_vm.label)}},[_c('div',{staticClass:"d-flex"},[_c('b-form-datepicker',{attrs:{"value":_vm.value[0],"date-format-options":{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },"locale":"ja","label-no-date-selected":"日付","reset-button":"","close-button":"","label-reset-button":_vm.$t('button.clear'),"label-close-button":_vm.$t('button.close'),"start-weekday":_vm.startDayOfWeek,"initial-date":_vm.today(),"size":"sm"},on:{"input":_vm.input1}}),_c('span',{staticClass:"widtout-form-group"},[_vm._v("~")]),_c('b-form-datepicker',{attrs:{"id":"inpuDate","value":_vm.value[1],"date-format-options":{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },"locale":"ja","label-no-date-selected":"日付","reset-button":"","close-button":"","label-reset-button":_vm.$t('button.clear'),"label-close-button":_vm.$t('button.close'),"start-weekday":_vm.startDayOfWeek,"initial-date":_vm.today(),"size":"sm","disabled":_vm.disabledTo},on:{"input":_vm.input2}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }